@font-face {
  font-family: "Roboto Condensed";
  src: url("../fonts/Robotocondensed.woff2") format("woff2"),
      url("../fonts/Robotocondensed.woff") format("woff"),
      url("../fonts/Robotocondensed.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("../fonts/Robotocondensedbold.woff2") format("woff2"),
      url("../fonts/Robotocondensedbold.woff") format("woff"),
      url("../fonts/Robotocondensedbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}