@font-face {
  font-family: "Roboto Condensed";
  src: url("../fonts/Robotocondensed.woff2") format("woff2"), url("../fonts/Robotocondensed.woff") format("woff"), url("../fonts/Robotocondensed.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("../fonts/Robotocondensedbold.woff2") format("woff2"), url("../fonts/Robotocondensedbold.woff") format("woff"), url("../fonts/Robotocondensedbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  position: relative;
  overflow-x: hidden;
}
html.menu-show {
  overflow-y: hidden;
}
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  font-family: "Roboto Condensed", Arial, sans-serif;
  line-height: 1.3;
  color: #000;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
ol,
ul {
  list-style-position: inside;
}
.wrap {
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  padding: 0 15px;
}
.btn {
  display: block;
  text-align: center;
  font-weight: 500;
  color: #000;
  background-color: #FFD2A4;
  padding: 12px 20px;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.btn:hover {
  color: #000;
  opacity: 0.8;
}
header {
  width: 100%;
  color: #fff;
  background-color: #1C1E53;
  padding: 24px 0;
  margin-bottom: 25px;
}
header .header__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .header__logo {
  font-size: 23px;
  font-weight: 700;
  text-transform: uppercase;
  max-height: 34px;
}
header .header__logo img {
  height: 100%;
  width: auto;
}
header .header__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
header .header__nav .close {
  display: none;
  position: relative;
  width: 30px;
  height: 30px;
  margin-bottom: 70px;
}
header .header__nav .close span {
  position: absolute;
  left: 0;
  top: 0;
  width: 34px;
  height: 6px;
  background-color: #fff;
  border-radius: 2px;
  transform-origin: left;
}
header .header__nav .close span:nth-child(1) {
  transform: rotate(45deg);
}
header .header__nav .close span:nth-child(2) {
  top: auto;
  bottom: 0;
  transform: rotate(-45deg);
}
header .header__nav ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
}
header .header__nav li {
  text-align: center;
  font-weight: 500;
  margin-left: 50px;
}
header .header__nav li:hover,
header .header__nav li.current {
  color: #FFD2A4;
}
header .header__btn {
  margin-left: 60px;
}
header .header__burger {
  display: none;
  position: relative;
  width: 30px;
  height: 15px;
}
header .header__burger span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #fff;
  transition: all 0.3s ease;
}
header .header__burger span:nth-child(2) {
  top: 6px;
}
header .header__burger span:nth-child(3) {
  top: 12px;
}
main {
  width: 100%;
  flex: 1;
}
.content {
  max-width: 830px;
  padding: 0 15px;
  margin: 0 auto 30px;
}
.content > * {
  margin-bottom: 25px;
}
.content h1,
.content h2,
.content h3 {
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
}
.content h1 {
  font-size: 36px;
}
.content h2 {
  font-size: 28px;
}
.content h3 {
  font-size: 20px;
}
.content img.full {
  width: 100%;
}
.content img.left {
  float: left;
  margin: 0 20px 15px 0;
}
.content img.right {
  float: right;
  margin-left: 0 0 15px 20px;
}
.content img.center {
  display: block;
  max-width: 100%;
  margin: 0 auto 12px;
}
.content blockquote {
  position: relative;
  font-size: 24px;
  padding: 10px 0 10px 27px;
}
.content blockquote:before {
  display: block;
  content: '';
  position: absolute;
  width: 3px;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #FFD2A4;
}
.content table {
  width: 100%;
  font-weight: 700;
  border-collapse: collapse;
}
.content table tr {
  border-bottom: 1px solid #FFD2A4;
}
.content table td {
  padding: 5px 10px;
}
.blog {
  width: 100%;
  background-color: #F1F1FF;
  margin-bottom: 50px;
  padding-top: 55px;
}
.blog__wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 120px;
}
.blog__title {
  font-size: 40px;
  font-weight: 700;
  width: 210px;
}
.blog__items {
  flex: 1;
}
.blog__item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #FFD2A4;
  padding: 0 30px;
  margin-bottom: 30px;
}
.blog__item:last-child {
  border-bottom: none;
}
.blog__item .item__number {
  font-size: 24px;
  font-weight: 500;
  margin-right: 60px;
}
.blog__item .item__text {
  font-weight: 500;
  margin-bottom: 30px;
}
.blog__item .item__question {
  font-size: 24px;
  cursor: pointer;
}
.blog__item .item__answer {
  display: none;
  padding-top: 15px;
  transition: all 0.3s ease;
}
.blog__item .item__answer.show {
  display: block;
}
.faq {
  width: 100%;
  margin-bottom: 50px;
}
.faq__wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 120px;
}
.faq__title {
  font-size: 40px;
  font-weight: 700;
  width: 210px;
}
.faq__items {
  flex: 1;
}
.faq__item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #D0D0D0;
  padding: 40px 95px 40px 50px;
  box-shadow: 0 32px 64px rgba(57, 20, 0, 0.04);
}
.faq__item:first-child {
  border-radius: 6px 6px 0 0;
}
.faq__item:last-child {
  border-radius: 0 0 6px 6px;
}
.faq__item .item__question {
  position: relative;
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  padding-right: 45px;
}
.faq__item .item__question:before {
  display: block;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  background-color: #1C1E53;
  border-radius: 50%;
}
.faq__item .item__question:after {
  display: block;
  content: '';
  position: absolute;
  right: 10px;
  top: 10px;
  width: 12px;
  height: 12px;
  background-image: url('../img/faq-arrow.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: rotate(180deg);
  transition: all 0.3s ease;
}
.faq__item .item__question.show:after {
  transform: rotate(0deg);
}
.faq__item .item__answer {
  display: none;
  line-height: 1.5;
  padding-top: 35px;
  transition: all 0.3s ease;
}
.faq__item .item__answer.show {
  display: block;
}
footer {
  width: 100%;
  color: #fff;
  background-color: #1C1E53;
  padding: 35px 0;
  margin-top: 40px;
}
footer .footer__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer .footer__copyright {
  font-size: 16px;
}
footer .footer__nav ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
}
footer .footer__nav li {
  text-align: center;
  margin-left: 50px;
}
footer .footer__nav li:hover,
footer .footer__nav li.current {
  color: #FFD2A4;
}
@media screen and (max-width: 991px) {
  header .header__nav li {
    margin-left: 25px;
  }
  .blog__wrap,
  .faq__wrap {
    padding-left: 15px;
  }
  footer .footer__nav li {
    margin-left: 25px;
  }
}
@media screen and (max-width: 768px) {
  header {
    padding: 10px 0;
  }
  header .header__btn {
    display: none;
  }
  header .header__burger {
    display: block;
  }
  header .header__nav {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100vh;
    min-height: 400px;
    background-color: #1C1E53;
    background-image: linear-gradient(180deg, #0D0D0D 0%, #111827 100%);
    padding: 25px 70px 25px 20px;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    transition: all 0.4s ease-in-out;
    z-index: 999999;
  }
  header .header__nav.active {
    left: 0;
    opacity: 1;
    visibility: visible;
  }
  header .header__nav .close {
    display: block;
  }
  header .header__nav ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  header .header__nav ul li {
    width: 100%;
    font-size: 24px;
    text-align: left;
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
    padding-bottom: 15px;
    margin: 0 0 15px 0;
  }
  .content h1 {
    font-size: 24px;
  }
  .content h2 {
    font-size: 20px;
  }
  .content h3 {
    font-size: 18px;
  }
  .blog {
    padding-top: 30px;
  }
  .blog__wrap {
    display: block;
  }
  .blog__title {
    display: block;
    margin-bottom: 20px;
  }
  .faq__wrap {
    display: block;
  }
  .faq__title {
    display: block;
    margin-bottom: 20px;
  }
  footer {
    padding: 10px 0;
  }
  footer .footer__wrap {
    justify-content: center;
  }
  footer .footer__nav {
    display: none;
  }
  footer .footer__copyright {
    font-size: 12px;
  }
}
@media screen and (max-width: 480px) {
  .content img.left,
  .content img.right {
    float: none;
    margin: 0 auto 15px;
    max-width: 100%;
  }
  .content ul,
  .content ol {
    padding-left: 0;
  }
  .blog__item {
    padding: 0;
  }
  .faq__item {
    padding: 20px;
  }
}
