@import "fonts";

@blue: #1C1E53;
@beige: #FFD2A4;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  position: relative;
  overflow-x: hidden;
}
html {
  &.menu-show {
    overflow-y: hidden;
  }
}
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  font-family: "Roboto Condensed", Arial, sans-serif;
  line-height: 1.3;
  color: #000;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
ol, ul {
  list-style-position: inside;
}

.wrap {
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  padding: 0 15px;
}

.btn {
  display: block;
  text-align: center;
  font-weight: 500;
  color: #000;
  background-color: @beige;
  padding: 12px 20px;
  border-radius: 4px;
  transition: all .3s ease;

  &:hover {
    color: #000;
    opacity: 0.8;
  }
}

header {
  width: 100%;
  color: #fff;
  background-color: @blue;
  padding: 24px 0;
  margin-bottom: 25px;

  .header {
    &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__logo {
      font-size: 23px;
      font-weight: 700;
      text-transform: uppercase;
      max-height: 34px;

      img {
        height: 100%;
        width: auto;
      }
    }
    &__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    &__nav {
      .close {
        display: none;
        position: relative;
        width: 30px;
        height: 30px;
        margin-bottom: 70px;
        
        span {
          position: absolute;
          left: 0;
          top: 0;
          width: 34px;
          height: 6px;
          background-color: #fff;
          border-radius: 2px;
          transform-origin: left;

          &:nth-child(1) {
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            top: auto;
            bottom: 0;
            transform: rotate(-45deg);
          }
        }
      }
      ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        list-style: none;
      }
      li {
        text-align: center;
        font-weight: 500;
        margin-left: 50px;
        
        &:hover,
        &.current {
          color: @beige;
        }
      }
    }
    &__btn {
      margin-left: 60px;
    }
    &__burger {
      display: none;
      position: relative;
      width: 30px;
      height: 15px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #fff;
        transition: all .3s ease;

        &:nth-child(2) {
          top: 6px;
        }
        &:nth-child(3) {
          top: 12px;
        }
      }
    }
  }
}

main {
  width: 100%;
  flex: 1;
}

.content {
  max-width: 830px;
  padding: 0 15px;
  margin: 0 auto 30px;

  & > * {
    margin-bottom: 25px;
  }
  h1, h2, h3 {
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
  }
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 20px;
  }
  img {
    &.full {
      width: 100%;
    }
    &.left {
      float: left;
      margin: 0 20px 15px 0;
    }
    &.right {
      float: right;
      margin-left: 0 0 15px 20px;
    }
    &.center {
      display: block;
      max-width: 100%;
      margin: 0 auto 12px;
    }
  }
  blockquote {
    position: relative;
    font-size: 24px;
    padding: 10px 0 10px 27px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 3px;
      height: 100%;
      left: 0;
      top: 0;
      background-color: @beige;
    }
  }
  table {
    width: 100%;
    font-weight: 700;
    border-collapse: collapse;

    tr {
      border-bottom: 1px solid @beige;
    }
    td {
      padding: 5px 10px;
    }
  }
}

.blog {
  width: 100%;
  background-color: #F1F1FF;
  margin-bottom: 50px;
  padding-top: 55px;

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 120px;
  }
  &__title {
    font-size: 40px;
    font-weight: 700;
    width: 210px;
  }
  &__items {
    flex: 1;
  }
  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid @beige;
    padding: 0 30px;
    margin-bottom: 30px;

    &:last-child {
      border-bottom: none;
    }
    .item {
      &__number {
        font-size: 24px;
        font-weight: 500;
        margin-right: 60px;
      }
      &__text {
        font-weight: 500;
        margin-bottom: 30px;
      }
      &__question {
        font-size: 24px;
        cursor: pointer;
      }
      &__answer {
        display: none;
        padding-top: 15px;
        transition: all .3s ease;

        &.show {
          display: block;
        }
      }
    }
  }
}

.faq {
  width: 100%;
  margin-bottom: 50px;

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 120px;
  }
  &__title {
    font-size: 40px;
    font-weight: 700;
    width: 210px;
  }
  &__items {
    flex: 1;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid #D0D0D0;
    padding: 40px 95px 40px 50px;
    box-shadow: 0 32px 64px rgba(57, 20, 0, 0.04);

    &:first-child {
      border-radius: 6px 6px 0 0;
    }
    &:last-child {
      border-radius: 0 0 6px 6px;
    }
    .item {
      &__question {
        position: relative;
        width: 100%;
        font-size: 24px;
        font-weight: 500;
        cursor: pointer;
        padding-right: 45px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          width: 32px;
          height: 32px;
          background-color: @blue;
          border-radius: 50%;
        }
        &:after {
          display: block;
          content: '';
          position: absolute;
          right: 10px;
          top: 10px;
          width: 12px;
          height: 12px;
          background-image: url('../img/faq-arrow.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          transform: rotate(180deg);
          transition: all .3s ease;
        }

        &.show {
          &:after {
            transform: rotate(0deg);
          }
        }
      }
      &__answer {
        display: none;
        line-height: 1.5;
        padding-top: 35px;
        transition: all .3s ease;

        &.show {
          display: block;
        }
      }
    }
  }
}

footer {
  width: 100%;
  color: #fff;
  background-color: @blue;
  padding: 35px 0;
  margin-top: 40px;

  .footer {
    &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__copyright {
      font-size: 16px;
    }
    &__nav {
      ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        list-style: none;
      }

      li {
        text-align: center;
        margin-left: 50px;

        &:hover,
        &.current {
          color: @beige;
        }
      }
    }
  }
}

@import "media";