@media screen and (max-width: 991px) {
  header {
    .header {
      &__nav {
        li {
          margin-left: 25px;
        }
      }
    }
  }

  .blog,
  .faq {
    &__wrap {
      padding-left: 15px;
    }
  }

  footer {
    .footer {
      &__nav {
        li {
          margin-left: 25px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  header {
    padding: 10px 0;

    .header {
      &__btn {
        display: none;
      }
      &__burger {
        display: block;
      }

      &__nav {
        position: fixed;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100vh;
        min-height: 400px;
        background-color: @blue;
        background-image: linear-gradient(180deg, #0D0D0D 0%, #111827 100%);
        padding: 25px 70px 25px 20px;
        opacity: 0;
        visibility: hidden;
        overflow-y: auto;
        transition: all .4s ease-in-out;
        z-index: 999999;

        &.active {
          left: 0;
          opacity: 1;
          visibility: visible;
        }
        .close {
          display: block;
        }
        ul {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          li {
            width: 100%;
            font-size: 24px;
            text-align: left;
            text-transform: uppercase;
            border-bottom: 1px solid #fff;
            padding-bottom: 15px;
            margin: 0 0 15px 0;
          }
        }
      }
    }
  }

  .content {
    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 20px;
    }
    h3 {
      font-size: 18px;
    }
  }

  .blog {
    padding-top: 30px;

    &__wrap {
      display: block;
    }
    &__title {
      display: block;
      margin-bottom: 20px;
    }
  }

  .faq {
    &__wrap {
      display: block;
    }
    &__title {
      display: block;
      margin-bottom: 20px;
    }
  }

  footer {
    padding: 10px 0;

    .footer {
      &__wrap {
        justify-content: center;
      }
      &__nav {
        display: none;
      }
      &__copyright {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .content {
    img {
      &.left,
      &.right {
        float: none;
        margin: 0 auto 15px;
        max-width: 100%;
      }
    }
    ul, ol {
      padding-left: 0;
    }
  }

  .blog {
    &__item {
      padding: 0;
    }
  }

  .faq {
    &__item {
      padding: 20px;
    }
  }
}